import styled from "styled-components"
import media from "styled-media-query"

import Img from "gatsby-image"
import { Link } from "gatsby"

export const Links = styled.section`
  color: var(--white);
  text-align: center;
  display: flex;
  padding: 3rem;
  flex-direction: column;

  ${media.lessThan("large")`
    padding: 1rem;
  `}
`
export const LinksProfile = styled(Img)`
  border-radius: 50%;
  width: 200px;
  height: 200px;
  display: inline-block;
  margin: 1rem auto;
  object-fit: cover;
`

export const LinksIdentify = styled.a`
  font-size: 1.25rem;
  margin: 1rem auto 2rem;
`

export const LinksLink = styled(Link)`
  width: 50vw;
  text-decoration: none;
  color: var(--white);
  border: 3px solid var(--black_background_contrast);
  text-transform: uppercase;
  padding: 1.5rem 1rem;
  margin: 0 auto 1.25rem;
  cursor: pointer;
  transition: background 0.3s;

  &:hover {
    background-color: var(--black_background_contrast);
    color: var(--orange);
  }

  ${media.lessThan("large")`
    width: 100%;
    color: ${props => (props.active ? "var(--white)" : "var(--orange)")};
    background: ${props => (props.active ? "var(--orange)" : "")};
    border: ${props =>
      props.active
        ? "3px solid var(--orange)"
        : "3px solid var(--black_background_contrast)"};
  `}
`

export const LinksA = styled.a`
  width: 50vw;
  text-decoration: none;
  color: var(--white);
  border: 3px solid var(--black_background_contrast);
  text-transform: uppercase;
  padding: 1.5rem 1rem;
  margin: 0 auto 1.25rem;
  cursor: pointer;
  transition: background 0.3s;

  &:hover {
    background-color: var(--black_background_contrast);
    color: var(--orange);
  }

  ${media.lessThan("large")`
    width: 100%;
    color: var(--orange);
    color: ${props => (props.active ? "var(--white)" : "var(--orange)")};
    background: ${props => (props.active ? "var(--orange)" : "")};
    border: ${props =>
      props.active
        ? "3px solid var(--orange)"
        : "3px solid var(--black_background_contrast)"};
  `}
`
