import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import GlobalStyles from "../../styles/global"
import * as S from "./styled"

const Links = () => {
  const { imageProfile } = useStaticQuery(graphql`
    query {
      imageProfile: file(
        relativePath: { eq: "profile/voce-empreendedor-digital-profile.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <>
      <GlobalStyles />
      <S.Links>
        <S.LinksProfile
          fluid={imageProfile.childImageSharp.fluid}
          alt="Foto de Willian D Santos - Empreendedor Digital"
        />
        <S.LinksIdentify>@williandsantos</S.LinksIdentify>
        <S.LinksA
          active="true"
          href="https://bit.ly/ved-minicurso-gpa-insta-bio"
          target="_blank"
        >
          Mini Curso Gratuito
        </S.LinksA>
        <S.LinksLink active="true" to="/materiais">
          Materiais Gratuitos
        </S.LinksLink>
        <S.LinksLink active="true" to="/bonus">
          Meus Bônus Platinum
        </S.LinksLink>
        <S.LinksA
          active="true"
          href="http://bit.ly/ved-link-instal-bio"
          target="_blank"
        >
          Curso GPA
        </S.LinksA>
        <S.LinksLink to="/">Meu Blog</S.LinksLink>
        <S.LinksA
          href="https://www.youtube.com/channel/UCwFrpRkQK4op-u4Q1BcqKtw"
          target="_blank"
        >
          Canal do Youtube
        </S.LinksA>
        <S.LinksLink to="/recomendacao">Recomendações</S.LinksLink>
        <S.LinksLink to="/contato">Fale Comigo</S.LinksLink>
        <S.LinksA
          href="https://open.spotify.com/show/3YOI3OWDzewy3wAK2lfBI0"
          target="_blank"
        >
          VED Cast
        </S.LinksA>
      </S.Links>
    </>
  )
}

export default Links
