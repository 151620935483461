import React from "react"

import SEO from "../components/seo"
import Links from "../components/Links"

const WilliandsantosPage = () => {
  return (
    <>
      <SEO
        title="Links"
        description="Página com links usada no instagram para poder direcionar vocês para o melhor conteúdo possível."
      />
      <Links />
    </>
  )
}

export default WilliandsantosPage
